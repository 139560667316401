<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="primary">查詢</mdb-card-header>
            <mdb-card-body class="pt-1">
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-select label="目前分店"
                                    placeholder="選擇目前分店"
                                    @getValue="val=>{search.id_store=val; }"
                                    v-model="storeList"></mdb-select>
                    </mdb-col>
                    <mdb-col md="3">
                        <datepicker label="進貨日期(起)"
                                    v-model="search.date_start"></datepicker>
                    </mdb-col>
                    <mdb-col md="3">
                        <datepicker label="進貨日期(迄)"
                                    v-model="search.date_end"></datepicker>
                    </mdb-col>
                    <mdb-col md="3"
                             class="pt-3 text-sm-right text-md-left">
                        <mdb-btn color="default"
                                 size="sm"
                                 class="mt-3"
                                 @click="search.page = 1;searchdata()">
                            <i class="fa fa-search"></i>
                            搜&nbsp;&nbsp;尋
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th style="width:3em">#</th>
                            <th style="width:10rem">分店</th>
                            <th style="width:10rem">進貨日期</th>
                            <th style="width:10rem">進貨人</th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s,sk) in purchaseList"
                            :key="`p_${sk}`">
                            <td>{{sk+1}}</td>
                            <td>
                                {{ storeKeyList[s.id_store] ? storeKeyList[s.id_store].name : '' }}
                            </td>
                            <td>
                                {{s.day}}

                            </td>
                            <td>
                                {{ adminKeyList[s.id_admin] ? adminKeyList[s.id_admin].name : '' }}
                            </td>
                            <td>
                                <mdb-btn color="grey darken-1"
                                         size="sm"
                                         @click="$router.push(`/product/purchase/view/${s.id}`)">
                                    <i class="fa fa-eye mr-2"></i>
                                    檢視
                                </mdb-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6"
                                v-show="purchaseList.length==0">無資料</td>
                        </tr>
                    </tbody>
                </table>
                <page :nowPage="data.nowPage"
                      :totalPage="data.totalPage"
                      @switchpage="pg=>{search.page=pg;searchdata();}" />
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbSelect,
} from "mdbvue";
import datepicker from "../../components/datepicker";
import page from "../../components/page";
export default {
  props: { query: { default: {} } },
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSelect,
    datepicker,
    page,
  },
  data() {
    return {
      search: { date_start: "", date_end: "", id_store: "", page: 1 },
      search_change: false,
      data: {
        nowPage: 1,
        totalPage: 0,
      },
      storeList: [],
      adminKeyList: {},
      purchaseList: [],
    };
  },
  mounted() {
    let vue = this;
    // 把篩選的資料放到變數裡面
    for (let i in vue.search) {
      switch (i) {
        case "id_store":
          vue.search[i] = vue.query[i]
            ? vue.query[i]
            : vue.$store.state.id_store;
          break;
        default:
          vue.search[i] = vue.query[i] ? vue.query[i] : "";
          break;
      }
    }
    this.getData();
  },
  watch: {
    search: {
      handler() {
        this.search_change = true;
      },
      deep: true,
    },
  },
  computed: {
    storeKeyList() {
      let output = {};
      this.storeList.forEach((item) => {
        output[item.id] = item;
      });
      return output;
    },
  },
  methods: {
    // 搜尋資料
    searchdata() {
      let vue = this;
      if (vue.search_change) {
        let url = vue.$route.path + "?";
        for (let k in vue.search) {
          url += `${k}=${vue.search[k]}&`;
        }
        vue.$router.push(url);
        vue.$nextTick(() => {
          vue.search_change = false;
        });
      }
      vue.getData();
    },
    getData() {
      let vue = this;
      let query = [
        {
          name: "admin",
          data: {},
        },
        {
          name: "store",
          data: {
            active: {
              type: 0,
              value: 1,
            },
          },
          sort: {
            name: 0,
          },
        },
      ];
      // 搜尋資料
      let obj = {
        name: "purchase",
        data: {},
        pg: vue.search.page == "" ? 1 : vue.search.page,
        sort: { day: 1 },
      };
      if (vue.search.id_store != "") {
        obj.data.id_store = {
          type: 0,
          value: vue.search.id_store,
        };
      }
      if (vue.search.date_start != "" && vue.search.date_end != "") {
        obj.data.day = {
          type: 7,
          value: [vue.search.date_start, vue.search.date_end],
        };
      } else if (vue.search.date_start != "") {
        obj.data.day = { type: 5, value: vue.search.date_start };
      } else if (vue.search.date_end != "") {
        obj.data.day = { type: 4, value: vue.search.date_end };
      }
      query.push(obj);
      query.push(Object.assign({ num: 1 }, obj));
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${encodeURI(JSON.stringify(query))}`,
          },
        })
        .then((res) => {
          if (res.data.status == "ok") {
            vue.data.totalPage = Math.ceil(res.data.purchaseNumber / 10);
            vue.data.nowPage = vue.search.page ? parseInt(vue.search.page) : 1;
            vue.purchaseList = res.data.purchaseList.map((item) => {
              item.item_list = JSON.parse(item.item_list);
              return item;
            });
            res.data.adminList.forEach((item) => {
              vue.adminKeyList[item.id] = item;
            });
            vue.storeList = res.data.storeList.map((item) => {
              item.value = item.id;
              item.text = item.name;
              item.selected = vue.search.id_store == item.id;
              return item;
            });
            vue.storeList.unshift({
              value: "",
              text: "全部",
              selected: vue.search.id_store == "",
            });
          }
        });
    },
  },
};
</script>